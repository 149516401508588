import '../App.css';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import logo from '../assets/logo.png';
import logo2 from '../assets/glitchLogo.gif';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import opensea from '../assets/os.png';
import twitter from '../assets/twitter.png';
import hacker from '../assets/hacker.png';
import FAQ from '../components/faq';
import loadingBluePill from '../assets/loading2.mp4';
import playBtn from '../assets/play-button.png';
import next from '../assets/next.png';
import previous from '../assets/previous.png';
import pauseBtn from '../assets/video-pause-button.png';
import audio from '../assets/song1.mp3';
import audio2 from '../assets/song2.mp3';
import audio3 from '../assets/song3.mp3';
import audio4 from '../assets/song4.mp3';
import audio5 from '../assets/song5.mp3';
import pepe from '../assets/pepe.png';
import dextools from '../assets/dextools.png';
import discord from '../assets/discord.png';
import vault from '../assets/vault.png';

var $ = require("jquery");

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const os = () => {
    window.open("https://testnets.opensea.io/collection/pepemaxi-3");
}

const discrd = () => {
    window.open("https://discord.gg/qgFdEwr2");
}

const pepeSite = () => {
    window.open("https://www.pepe.vip/");
}

const tweet = () => {
    window.open("https://twitter.com/PEPEMaxi_io");
}

const dextoolsL = () => {
    window.open("https://www.dextools.io/app/en/ether/pair-explorer/0xa43fe16908251ee70ef74718545e4fe6c5ccec9f");
}

let song;
let song1;

let playlist = [audio2, audio3, audio, audio4, audio5];
let currentIndex = 0;

const primaryColor = "#0fce0f";
const source = "ﾄﾈｿﾏﾊﾎｩｶﾕｫﾍｪｵﾛｬﾚｸｺﾌｱﾒｰｦｽﾁﾜﾂｹﾗﾓｭﾐﾙﾑﾆｳﾝｯｲﾃﾀﾘｮﾔｾﾋﾅｼｷｴﾖﾉﾇｧｻｨ1234567890";
const getRndInteger = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
let interval;

const start = () => {
    const canvas = $("#container2")[0];
    const rect = canvas.parentElement.getBoundingClientRect();
    canvas.width = rect.width;
    canvas.height = rect.height;
    const ctx = canvas.getContext("2d");
    const size = 14;
    ctx.font = `${size}px sans-serif`;
    const columns = Math.round(canvas.width / size);
    const rows = Math.round(canvas.height / size);
    const columnCells = Array(columns)
        .fill(0)
        .map(() => getRndInteger(0, canvas.height));

    const render = () => {
        ctx.fillStyle = "rgba(0, 0, 0,0.1)"; // Set the desired opacity here
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = primaryColor;
        ctx.globalAlpha = 1; // Reset opacity to 1 before rendering text
        columnCells.forEach((y, index) => {
            const text = source[getRndInteger(0, source.length + 2)] || "";
            const x = index * size;
            ctx.fillText(text, x, y);
            columnCells[index] =
                y > canvas.height + getRndInteger(0, 10000) ? 0 : y + size;
        });
    };

    interval = setInterval(render, 60);
};

$(window).on('load', function () {
    setTimeout(function () { // allowing 3 secs to fade out loader
        $('.page-loader2').fadeOut('slow');
    }, 3000);
});


class About extends Component {

    state = {
        _navbarOpen: 0,
        sound: 0,
        _ser: 0,
        _bluePill: 0,
        _paused: '',
        _navbarOpen: 0,
		songNames: ["White Rabbit", "Where Is My Mind", "Dissolved girl", "Poetry of Madness", "Death of a Hero"],
		artist: ["Song by Jefferson Airplane", "Song by Pixies", "Song by Massive Attack", "Song by Secession Studios", "Song by Emanuele Patetta"]
    };



    closeNav = () => {
        const navBar = document.querySelector(".littleNav");
        navBar.classList.add("hidden");
    }

    closeNav = async event => {
        event.preventDefault();

        this.setState({ _navbarOpen: 0 });
    }

    componentDidMount() {
        try {

            if (song != null) {
                song.play();
            } else {
                song = new Audio(playlist[currentIndex]);
                song.play();
            }
            this.setState({ _ser: 1 });
            song.addEventListener('ended', this.playNext);

            $(function () {
                start();
                $(window).on("resize", () => {
                    clearInterval(interval);
                    start();
                });
            });
        } catch (err) {
            console.log(err);
        }
    }


    navbarOpen = async event => {
        event.preventDefault();

        this.setState({ _navbarOpen: 1 });
    }

    playAudio = async event => {
        event.preventDefault();

        if (song != null) {
            song.play();
        } else {
            song = new Audio(playlist[currentIndex]);
            song.play();
        }
        this.setState({ _ser: 1 });
        song.addEventListener('ended', this.autoPlayNext);

    }

    autoPlayNext = async event => {
        event.preventDefault();

        console.log("auto play");
        currentIndex = (currentIndex + 1);
        if (currentIndex > 4) {
            currentIndex = 0;
        }
        song = new Audio(playlist[currentIndex]);

        song.play();
        this.setState({ _ser: 1 });

         song.addEventListener('ended', this.autoPlayNext);


    }

    pauseAudio = async event => {
        event.preventDefault();
        song.pause();

        this.setState({ _ser: 0 });

    }

    playNext = async event => {
        event.preventDefault();
        if (song != null) {
            song.pause();
        }
        currentIndex = (currentIndex + 1);
        if (currentIndex > 4) {
            currentIndex = 0;
        }
        song = new Audio(playlist[currentIndex]);

        song.play();
        this.setState({ _ser: 1 });

        console.log("playNext");
        song.addEventListener('ended', this.autoPlayNext);

    }

    playPrevious = async event => {
        event.preventDefault();
        if (song != null) {
            song.pause();
        }
        currentIndex = (currentIndex - 1);
        if (currentIndex < 0) {
            currentIndex = 4;
        }
        song = new Audio(playlist[currentIndex]);

        song.play();
        this.setState({ _ser: 1 });

        console.log("playPrevious");
        song.addEventListener('ended', this.autoPlayNext);


    }

    render() {

        return (

            <div class="allWrap2">
                <div class="surrounded">

                    <div class="page-loader2">
                        <div class="vidCont">
                            <video class="videoBg" src={loadingBluePill} autoPlay loop playsInline muted></video>
                        </div>
                    </div>
                    <div class="light2">


                        <canvas id="container2"></canvas>

                        <div class="cont2">

                            {this.state._navbarOpen < 1 ?
                                (<div class="miniBarMain">
                                    <div class="logoDiv"><img class="logo" onClick={() => window.location.href = '/'} src={logo2} />
                                        <div class="glitch-wrapper">
                                            <div class="glitch" data-text="PEPEMAXI" onClick={() => window.location.href = '/'}>PEPEMAXI</div>
                                        </div>
                                    </div>

                                    <div class="navbarMain"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
                                </div>) :
                                (<div class="miniBarMain">
                                    <div class="logoDiv"><img class="logo" onClick={() => window.location.href = '/'} src={logo2} />
                                        <div class="glitch-wrapper">
                                            <div class="glitch" data-text="PEPEMAXI" onClick={() => window.location.href = '/'}>PEPEMAXI</div>
                                        </div>
                                    </div>
                                    <div class="navbarMain"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
                                </div>)}
                            <div class="headers3">

                                <div class="h1">

                                    <div class="left">
                                        <div class="logoDiv"><img class="logo" onClick={() => window.location.href = '/'} src={logo2} /></div>

                                        <div class="connect">
                                            <div class="glitch-wrapper">
                                                <div class="glitch" data-text="PEPEMAXI" onClick={() => window.location.href = '/'}>PEPEMAXI</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="right">
                                    <img class="rightImg" src={vault} onClick={() => window.location.href = 'PepeVault'} />
                                        <img class="rightImg" onClick={dextoolsL} src={dextools} />
                                        <img class="rightImg" onClick={discrd} src={discord} />
                                        <img class="rightImg" onClick={os} src={opensea} />
                                        <img class="rightImg" onClick={tweet} src={twitter} />
                                        <img class="pepeImg" onClick={pepeSite} src={pepe} />

                                    </div>

                                </div>

                            </div>

                            {this.state._navbarOpen > 0 ?
                                (<div class="littleNav">
                                    <div id="fontSize" onClick={() => window.location.href = 'about'}><span class='activeClass'>ABOUT</span></div>
                                    <div id="fontSize" onClick={() => window.location.href = '/'}>MINTING</div>
                                    <div id="fontSize" onClick={() => window.location.href = 'whitelist'}>CHASE THE WHITE RABBIT</div>
                                    <div id="fontSize" onClick={() => window.location.href = 'PepeVault'}>PEPE VAULT</div>

                                    <div class="iconsLittle">
                                    <img class="rightImg" src={vault} onClick={() => window.location.href = 'PepeVault'} />
                                        <img class="rightImg" onClick={dextoolsL} src={dextools} />
                                        <img class="rightImg" onClick={discrd} src={discord} />
                                        <img onClick={os} class="rightImg" src={opensea} />
                                        <img class="rightImg" onClick={tweet} src={twitter} />
                                        <img class="pepeImg" onClick={pepeSite} src={pepe} />

                                    </div>

                                </div>) : null}

                            <div class="boxWrap2Story">

                                <div class="about">
                                    <div class="conT">Our Purpose...</div>
                                    <div class="storyCon">
                                        <p>Our mission is to revolutionize and rebrand the web3 space by creating a unique and innovative digital art experience that is accessible for ALL. We aim to provide a platform for artists and builders to showcase their talent and creativity, while also giving collectors the opportunity to own art they connect with.</p>
                                        <p>Our goal is to break the Matrix of the centralized world and create a community that supports artists and collectors who share a passion for digital art and who are committed to pushing the boundaries of what is possible in this exciting new medium. Through our commitment to quality, innovation, and accessibility, we strive to become the leading platform for NFT art and to inspire a new generation of artists and collectors around the world. Let's create a future we ALL can be proud of that incorporates code, blockchain, art and community.</p>
                                        <p>For to long they’ve kept you under their corrupt and broken system. What pill will you choose Anon?</p>
                                        <p><span class="hl">Are you ready to chase the White Rabbit…</span></p>
                                    </div>
                                </div>

                            </div>

                            <div class="boxWrap2Story">

                                <div class="about">
                                    <div class="conT">The Matrix Manifesto…</div>
                                    <div class="storyCon">
                                        <p>Since the beginning there has always been a select few given the power over the majority.</p>
                                        <p>This broken and corrupt centralized system benefits only those in control.</p>
                                        <p>Take the green pill to join the Resistance and break the Matrix.</p>
                                        <p>So your eyes will see the corruption and truth.</p>
                                        <p>Do you want to go back to bed and continue to be controlled by the few?</p>
                                        <p>Or do you want to join us and wake up free?</p>
                                        <p>Chase the white rabbit anon and break the Matrix…</p>
                                        <p><span class="hl">Join the Resistance. Take the Green Pill</span></p>
                                    </div>
                                </div>

                            </div>

                            <FAQ />

                        </div>


                    </div>
                    <div class="audioMainAb">
                        <div class="audioMainNew">

                            <img class="audioLogo2" src={logo} />

                            {this.state._ser < 1 ? (

                                <div class="audioMain2New">

                                    <div class="music-bar"><span class="bar2 bar-0">0</span><span class="bar2 bar-1">1</span><span class="bar2 bar-2">2</span><span class="bar2 bar-3">3</span><span class="bar2 bar-4">4</span>
                                    </div>

                                    <div class="songDetails">
                                        <div class="songName">{this.state.songNames[currentIndex]}</div>
                                        <div class="artist">{this.state.artist[currentIndex]}</div>
                                    </div>

                                    <img class="playBtn2" onClick={this.playPrevious} src={previous} />
                                    <img class="playBtn" onClick={this.playAudio} src={playBtn} />
                                    <img class="playBtn2" onClick={this.playNext} src={next} />
                                </div>) :
                                (<div class="audioMain2New">
                                    <div class="music-bar"><span class="bar bar-0">0</span><span class="bar bar-1">1</span><span class="bar bar-2">2</span><span class="bar bar-3">3</span><span class="bar bar-4">4</span>
                                    </div>

                                    <div class="songDetails">
                                        <div class="songName">{this.state.songNames[currentIndex]}</div>
                                        <div class="artist">{this.state.artist[currentIndex]}
                                        </div>
                                    </div>

                                    <img class="playBtn2" onClick={this.playPrevious} src={previous} />
                                    <img class="playBtn" onClick={this.pauseAudio} src={pauseBtn} />
                                    <img class="playBtn2" onClick={this.playNext} src={next} />
                                </div>)}

                        </div>
                    </div>
                </div>

            </div>)
    }
}

export default About;

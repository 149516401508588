import React, { Component } from 'react';

class FAQ extends Component {

  render() {
    return (

      <div id="faq" class="faqBg">

        <div class="accordin reveal">

          <div class="conT">Frequently Asked Questions</div>
      <p></p>
      <p></p>
          <div data-aos="fade-up" data-aos-duration="1000">
            <summary>What is the roadmap?</summary>
            <div class="faq__content">
              <p>We don't believe in roadmaps like we don't believe in the broken centralized system. Roadmaps are just fake promises. We believe in creating a sustainable long term culture and experience!</p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <summary>What is CC0?</summary>
            <div class="faq__content">
            <p>This means there’s no copyright for the NFT collection. You can use for commercial purposes, all without asking permission from the team. You buy the NFT you own the rights…</p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <summary>What is the total NFT collection supply?</summary>
            <div class="faq__content">
              <p>The total collection is 6969 because we're degens.</p>
            </div>
          </div>

          <div data-aos="fade-up" data-aos-duration="1000">
            <summary>What is the Public mint price?</summary>
            <div class="faq__content">
              <p>Public Mint price will be .0069 Eth</p>
            </div>
          </div>
          
          <div data-aos="fade-up" data-aos-duration="1000">
            <summary>What is the Matrix List Mint Price?</summary>
            <div class="faq__content">
              <p>Those who take the green pill and chase the white rabbit will mint for free</p>
            </div>
          </div>
          
          <div data-aos="fade-up" data-aos-duration="1000">
            <summary>How do you get Matrix List?</summary>
            <div class="faq__content">
              <p>1500 Matrix List will be allocated to those Anons who chase the white rabbit and break the Matrix</p>
            </div>
          </div>

         <div data-aos="fade-up" data-aos-duration="1000">
            <summary>Who’s the team?</summary>
            <div class="faq__content">
              <p>We’re a team of entrepreneurs, developers, and advisors from around the world. We’ve been actively in the Crypto and NFT industry and wanted to create a project that is truly for the community. No more self-centered founders who care nothing about their holders.</p>
              <p>Break the Matrix and create a space that's inclusive for those who seek the truth…</p>
            </div>
          </div>
   
        </div>

      </div>


    )
  }
}

export default FAQ;


import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/home";
import About from "./Pages/about";
import BluePill from "./Pages/bluePill";
import Whitelist from "./Pages/whitelist";
import PepeVault from "./Pages/pepevault";
  
function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='about' element={<About />} />
					<Route path='bluepill' element={<BluePill />} />
					<Route path='Whitelist' element={<Whitelist />} />
					<Route path='PepeVault' element={<PepeVault />} />
 				 
				</Routes>
			</BrowserRouter>
			<div>
			</div>
		</div>
	)
}


export default App;

import '../App.css';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import logo from '../assets/logo.png';
import logo2 from '../assets/glitchLogo.gif';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import opensea from '../assets/os.png';
import twitter from '../assets/twitter.png';
import hacker from '../assets/hacker.png';
import pepe from '../assets/pepe.png';
import hackerMobile from '../assets/hackerMobile.png';
import pill from '../assets/pill.png';
import pill2 from '../assets/pill2.png';
import musicOn from '../assets/head-phones.png';
import musicOff from '../assets/head-phones-off.png';
import loadingBluePill from '../assets/loading2.mp4';
import audio from '../assets/song1.mp3';
import audio2 from '../assets/song2.mp3';
import audio3 from '../assets/song3.mp3';
import audio4 from '../assets/song4.mp3';
import audio5 from '../assets/song5.mp3';
import playBtn from '../assets/play-button.png';
import next from '../assets/next.png';
import previous from '../assets/previous.png';
import pauseBtn from '../assets/video-pause-button.png';
import wl from '../assets/whiteRabbit.jpg';
import mintBtn from '../assets/mintBtn.png';
import mintBtnHover from '../assets/mintBtnHover.png';
import connectBtnHover from '../assets/connectBtnHover.png';
import connectBtn from '../assets/connectBtn.png';
import mintHere from '../assets/mintHere.png';
import plus from '../assets/plus.png';
import minus from '../assets/minus.png';
import mintHereHover from '../assets/mintHereHover.png';
import dextools from '../assets/dextools.png';
import discord from '../assets/discord.png';
import vault from '../assets/vault.png';

var $ = require("jquery");

var Scroll = require('react-scroll');

const os = () => {
	window.open("https://testnets.opensea.io/collection/pepemaxi-3");
}

const discrd = () => {
	window.open("https://discord.gg/qgFdEwr2");
}

const tweet = () => {
	window.open("https://twitter.com/PEPEMaxi_io");
}

const pepeSite = () => {
	window.open("https://www.pepe.vip/");
}

const dextoolsL = () => {
	window.open("https://www.dextools.io/app/en/ether/pair-explorer/0xa43fe16908251ee70ef74718545e4fe6c5ccec9f");
}

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let song_1;
let song_2;
let song_3;
let song;
let song1;
let song2;
let song3;
let song4;

let playlist = [audio3, audio2, audio, audio4, audio5];
let currentIndex = 0;

const ABI = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_total_PS_limit",
				"type": "uint256"
			}
		],
		"name": "setTotal_PS_limit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_total_wl_limit",
				"type": "uint256"
			}
		],
		"name": "setTotal_wl_limit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "twitterhandle",
				"type": "string"
			},
			{
				"internalType": "address",
				"name": "wallet_inputAddress",
				"type": "address"
			}
		],
		"name": "setTwitterHandleWithWallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_whitelistCost",
				"type": "uint256"
			}
		],
		"name": "setWhitelistCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_wl_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setWl_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_wl_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "whitelistMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "mintedFree",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "publicMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_PS_count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_PS_limit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_wl_count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_wl_limit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "twitterWithWalletAddress",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"name": "walletWithTwitter",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wl_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wl_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "wlEligible",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "wlMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "wlWallets",
		"outputs": [
			{
				"internalType": "string",
				"name": "twitterhandle",
				"type": "string"
			},
			{
				"internalType": "address",
				"name": "walletAddress",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0x6b4425127e30C70170b93a2c7daB95800E0ef737";

let contract;

const primaryColor = "#0fce0f";
const source = "ﾄﾈｿﾏﾊﾎｩｶﾕｫﾍｪｵﾛｬﾚｸｺﾌｱﾒｰｦｽﾁﾜﾂｹﾗﾓｭﾐﾙﾑﾆｳﾝｯｲﾃﾀﾘｮﾔｾﾋﾅｼｷｴﾖﾉﾇｧｻｨ1234567890";
const getRndInteger = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
let interval;
const start = () => {
	const canvas = $("#container")[0];
	const rect = canvas.parentElement.getBoundingClientRect();
	canvas.width = rect.width;
	canvas.height = rect.height;
	const ctx = canvas.getContext("2d");
	const size = 14;
	ctx.font = `${size}px sans-serif`;
	const columns = Math.round(canvas.width / size);
	const rows = Math.round(canvas.height / size);
	const columnCells = Array(columns)
		.fill(0)
		.map(() => getRndInteger(0, canvas.height));
	const render = () => {
		ctx.fillStyle = "rgba(0,0,0,.08)";
		ctx.fillRect(0, 0, canvas.width, canvas.height);
		ctx.fillStyle = primaryColor;
		columnCells.forEach((y, index) => {
			const text = source[getRndInteger(0, source.length + 2)] || "";
			const x = index * size;
			ctx.fillText(text, x, y);
			columnCells[index] =
				y > canvas.height + getRndInteger(0, 10000) ? 0 : y + size;
		});
	};
	interval = setInterval(render, 60);
};


$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 3500);
});

class Home extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: 1,
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		gallery: '',
		_navbarOpen: 0,
		sound: 0,
		_music1: 0,
		_music2: 0,
		_music3: 0,
		_bluePill: 0,
		_ser: 0,
		_ser2: 0,
		_ser3: 0,
		_paused: '',
		songNames: ["Where Is My Mind", "White Rabbit", "Dissolved girl", "Poetry of Madness", "Death of a Hero"],
		artist: ["Song by Pixies", "Song by Jefferson Airplane", "Song by Massive Attack", "Song by Secession Studios", "Song by Emanuele Patetta"]
	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}

	async componentDidMount() {

		try {

			$(function () {
				start();
				$(window).on("resize", () => {
					clearInterval(interval);
					start();
				});
			});


			if (localStorage?.getItem('src') == "1") {
				localStorage.setItem('src', "2");

				this.setState({ gallery: "1" });

			} else

				if (localStorage?.getItem('src') == "2") {
					localStorage.setItem('src', "3");

					this.setState({ gallery: "2" });

				} else

					if (localStorage?.getItem('src') == "3") {
						localStorage.setItem('src', "4");

						this.setState({ gallery: "3" });

					} else

						if (localStorage?.getItem('src') == "4") {
							localStorage.setItem('src', "1");

							this.setState({ gallery: "4" });

						} else {

							localStorage.setItem('src', "1");
							this.setState({ gallery: "1" });

						}


		} catch (err) {
			console.log(err);
		}

		if (localStorage?.getItem('isWalletConnected') === 'true') {
			try {
				const providerOptions = {
					walletconnect: {
						package: WalletConnectProvider, // required
						options: {
							infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
						}
					},
					/*coinbasewallet: {
						package: CoinbaseWalletSDK, // Required
						options: {
							appName: "Aterium Universe", // Required
							infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
							rpc: "", // Optional if `infuraId` is provided; otherwise it's required
							chainId: 1, // Optional. It defaults to 1 if not provided
							darkMode: true // Optional. Use dark theme, defaults to false
						}
					}*/
				};

				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});



				const provider = await web3Modal.connect();

				//  Enable session (triggers QR Code modal)
				await provider.enable();

				const web3 = new Web3(provider);
				console.log("provider : " + provider);
				// Subscribe to accounts change
				provider.on("accountsChanged", (accounts) => {
					console.log(accounts);
				});

				// Subscribe to chainId change
				provider.on("chainChanged", (chainId) => {
					console.log(chainId);
				});

				// Subscribe to provider connection
				provider.on("connect", (info) => {
					console.log(info);
					console.log("I'm LOGGED IN");
				});

				// Subscribe to provider disconnection
				provider.on("disconnect", (error) => {
					console.log(error);
				});

				// test if wallet is connected
				if (web3Modal.cachedProvider) {
					// connected now you can get accounts
					console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
					console.log("provider :" + provider);

					const accounts = await web3.eth.getAccounts();

					account = accounts[0];
					this.setState({ walletAddress: account });

					//const signer = (new Web3Provider(window.ethereum).getSigner)();

					contract = new web3.eth.Contract(ABI, address); console.log("contract :" + contract);

					if (provider) {


						//	(async () => {

						if (web3Modal.cachedProvider != "walletconnect" /*&& web3Modal.cachedProvider != "coinbasewallet"*/) {

							const chainId = 5;

							if (window.ethereum.networkVersion !== chainId) {
								try {
									await window.ethereum.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

													chainName: 'Goerli Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],

													/*chainName: 'Rinkeby Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });

								MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
								this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
								console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

								public_mint_status = await contract.methods.public_mint_status().call();
								this.setState({ _public_mint_status: public_mint_status });
								console.log("public_mint_status :" + public_mint_status);

								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);
								//...........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 6969 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 6969) {

										onlyLeft = 6969 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = MAX_PER_WALLET - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///.................................///



								//...........................................................................//

							}
							catch (err) {
								console.log("err: " + err);

							}

						} else if (web3Modal.cachedProvider == "walletconnect") {

							const chainId = 5;

							if (WalletConnectProvider.networkVersion !== chainId) {
								try {
									await WalletConnectProvider.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

													chainName: 'Goerli Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],

													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });

								MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
								this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
								console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

								public_mint_status = await contract.methods.public_mint_status().call();
								this.setState({ _public_mint_status: public_mint_status });
								console.log("public_mint_status :" + public_mint_status);

								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);
								//...........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 6969 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 6969) {

										onlyLeft = 6969 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = MAX_PER_WALLET - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///.................................///



								//...........................................................................//

							} catch (err) {
								console.log("err: " + err);

							}


						}
						//})();

						//......................................................................//

						// Legacy providers may only have ethereum.sendAsync
						const chainId = await provider.request({
							method: 'eth_chainId'
						})

					} else {

						// if the provider is not detected, detectEthereumProvider resolves to null
						console.error('Please install a Valid Wallet');
						alert('A valid provider could not be found!');

					}

				}
			} catch (ex) {
				console.log(ex);
			}
		} else {

			console.log("NOT CONNECTED");

		}

	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 6969 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {
			if (totalSupplyNFT < 6969) {

				onlyLeft = MAX_PER_WALLET - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });
				console.log("nftMintingAmount - hitting - :" + this.state.nftMintingAmount);

				this.setState({ totalValue: valueOfNFTs });
			}
		}

	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 6969 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 6969) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });
				console.log("nftMintingAmount - hitting + :" + this.state.nftMintingAmount);

				this.setState({ totalValue: valueOfNFTs });
			}
		}

	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 1 });

					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 0 });


					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}

			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });
			//const signer = (new Web3Provider(window.ethereum).getSigner)();

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);
			//const provider = new Provider('https://testnet.era.zksync.dev');
			// Note that we still need to get the Metamask signer
			/*const signer = (new Web3Provider(window.ethereum).getSigner)();
			contractZksync = new web3.eth.Contract(
				ABI,
				address,
				signer
			);
			this.setState({ _provider:provider, _signer:signer, _contractZksync:contractZksync });
			console.log("provider : " + provider);
			console.log("signer : " + signer);
			console.log("contractZksync : " + contractZksync);*/

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 5;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

												chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}





						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);
							//...........................................................................//
							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 6969 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 6969) {

									onlyLeft = 6969 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///.................................///



							//...........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 5;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

												/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

												chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],
												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}




						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);



							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 6969 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 6969) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///.................................///



							//...........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//......................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload(true);

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}

	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	bluePillOpen = async event => {
		event.preventDefault();

		this.setState({ _bluePill: 1 });
		await new Promise(resolve => setTimeout(resolve, 1000));

		console.log("bluePill :" + this.state._bluePill);
	}

	pauseMusic3 = async event => {
		event.preventDefault();

		song1.pause();

		this.setState({ _ser: 0 });
	}

	playAudio = async event => {
		event.preventDefault();

		if (song != null) {
			song.play();
		} else {
			song = new Audio(playlist[currentIndex]);
			song.play();
		}
		this.setState({ _ser: 1 });
		song.addEventListener('ended', this.autoPlayNext);

	}

	autoPlayNext = async event => {
		event.preventDefault();

		console.log("auto play");
		currentIndex = (currentIndex + 1);
		if (currentIndex > 4) {
			currentIndex = 0;
		}
		song = new Audio(playlist[currentIndex]);

		song.play();
		this.setState({ _ser: 1 });

		song.addEventListener('ended', this.autoPlayNext);


	}

	pauseAudio = async event => {
		event.preventDefault();
		song.pause();

		this.setState({ _ser: 0 });

	}

	playNext = async event => {
		event.preventDefault();
		if (song != null) {
			song.pause();
		}
		currentIndex = (currentIndex + 1);
		if (currentIndex > 4) {
			currentIndex = 0;
		}
		song = new Audio(playlist[currentIndex]);

		song.play();
		this.setState({ _ser: 1 });

		console.log("playNext");
		song.addEventListener('ended', this.autoPlayNext);

	}

	playPrevious = async event => {
		event.preventDefault();
		if (song != null) {
			song.pause();
		}
		currentIndex = (currentIndex - 1);
		if (currentIndex < 0) {
			currentIndex = 4;
		}
		song = new Audio(playlist[currentIndex]);

		song.play();
		this.setState({ _ser: 1 });

		console.log("playPrevious");
		song.addEventListener('ended', this.autoPlayNext);


	}

	closeWindow = async event => {
		event.preventDefault();

		this.setState({ statusError: false });
		this.setState({ statusLoading: false });
		this.setState({ success: false });
	}


	render() {

		return (

			<div class="allWrap">
				<div class="light">
					<div class="page-loader">
						<canvas id="container"></canvas>

						<div class="txt"><span class="loadingText">LOADING</span></div>
					</div>
					<div class="cont">
						{this.state._navbarOpen < 1 ?
							(<div class="miniBarMain">
								<div class="logoDiv"><img class="logo" onClick={() => window.location.href = '/'} src={logo2} />
									<div class="glitch-wrapper">
										<div class="glitch" data-text="PEPEMAXI">PEPEMAXI</div>
									</div>
								</div>

								<div class="navbarMain"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
							</div>) :
							(<div class="miniBarMain">
								<div class="logoDiv"><img class="logo" onClick={() => window.location.href = '/'} src={logo2} />
									<div class="glitch-wrapper">
										<div class="glitch" data-text="PEPEMAXI">PEPEMAXI</div>
									</div>
								</div>
								<div class="navbarMain"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
							</div>)}
						<div class="headers">

							<div class="h1">

								<div class="left" >
									<div class="logoDiv"><img class="logo" onClick={() => window.location.href = '/'} src={logo2} /></div>

									<div class="connect" >
										<div class="glitch-wrapper">
											<div class="glitch" data-text="PEPEMAXI">PEPEMAXI</div>
										</div>
									</div>
								</div>
								<div class="right">
									<img class="rightImg" src={vault} onClick={() => window.location.href = 'PepeVault'} />
									<img class="rightImg" onClick={dextoolsL} src={dextools} />
									<img class="rightImg" onClick={discrd} src={discord} />
									<img class="rightImg" onClick={os} src={opensea} />
									<img class="rightImg" onClick={tweet} src={twitter} />
									<img class="pepeImg" onClick={pepeSite} src={pepe} />

									{this.state.walletAddress === '' ?
										(<form class="connect2">
											<button onClick={this.walletConnect} class="connectBtn" >WALLET CONNECT</button>
										</form>) : (<form class="connect2" onClick={this.walletDisconnect}><button class="connectBtn" >
											{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

								</div>

							</div>

						</div>

						{this.state._navbarOpen > 0 ?
							(<div class="littleNav">
								<div id="fontSize" onClick={() => window.location.href = '/'}><span class='activeClass'>MINTING</span></div>
								<div id="fontSize" onClick={() => window.location.href = 'about'}>ABOUT</div>
								<div id="fontSize" onClick={() => window.location.href = 'whitelist'}>CHASE THE WHITE RABBIT</div>
								<div id="fontSize" onClick={() => window.location.href = 'PepeVault'}>PEPE VAULT</div>
								<div class="iconsLittle">
								<img class="rightImg" src={vault} onClick={() => window.location.href = 'PepeVault'} />
									<img class="rightImg" onClick={dextoolsL} src={dextools} />
									<img class="rightImg" onClick={discrd} src={discord} />
									<img onClick={os} class="rightImg" src={opensea} />
									<img class="rightImg" onClick={tweet} src={twitter} />
									<img class="pepeImg" onClick={pepeSite} src={pepe} />

								</div>
								<div>{this.state.walletAddress === '' ?
									(<form class="connect2">
										<button onClick={this.walletConnect} class="connectBtn" >WALLET CONNECT</button>
									</form>) : (<form class="connect2" onClick={this.walletDisconnect}><button class="connectBtn" >
										{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}</div>
							</div>) : null}

					</div>


					<div class="mintArea">

						{this.state.walletAddress === '' ?
							(<div><div class="glitch2-wrapper">
								<div class="glitch2" data-text="Break The Matrix">Break The Matrix</div>
							</div>
								<div class="mintbuttondiv">
									<img class="mintBtn" onClick={this.walletConnect} src={connectBtn} />
									<img class="mintBtnHover" onClick={this.walletConnect} src={connectBtnHover} />

								</div>
							</div>) :
							(<div class="mintbuttondiv">
								<div class="minting_count_button">

									<div class="center">
										<img onClick={this.onSubmitMinus} src={minus} class="btnfos-0-2"></img>
									</div>

									<div>
										<span class="nftamount">{this.state.nftMintingAmount}</span>
									</div>

									<div class="center">
										<img onClick={this.onSubmitPlus} src={plus} class="btnfos-0-2"></img>
									</div>
								</div>
								<div>
									<div class="mintbuttondiv">
										<img class="mintBtn" onClick={this.onSubmit2} src={mintBtn} />
										<img class="mintBtnHover" onClick={this.onSubmit2} src={mintBtnHover} />
									</div>
								</div>

							</div>)}

						<div>

							{this.state.statusError ?
								(<div class="formMain2">
									<div class="formMain-2">
										<form onClick={this.closeWindow}>
											<button class="closeNote">✖</button>
										</form>
										<div>
											<div class="errorMessage">
												<div >Sorry, something went wrong please try again later</div>
											</div>

											<div class="pepe">- PEPEMAXI -</div>
										</div>
									</div>

								</div>) : null}

							{this.state.statusLoading ?
								(<div class="formMain2">
									<div class="formMain-2">
										<form onClick={this.closeWindow}>
											<button class="closeNote">✖</button>
										</form>
										<div class="loadingContainer">
											<div>
												<div class="loadingText"><span class="loadFont">Minting your NFT</span></div>
												<div class="loading-bar"></div>
											</div>
											<div class="pepe">- PEPEMAXI -</div>
										</div>

									</div>

								</div>) : null}

							{this.state.success ?
								(<div class="formMain2">
									<div class="formMain-2">
										<form onClick={this.closeWindow}>
											<button class="closeNote">✖</button>
										</form>
										<div>
											<div class="success-message">
												<h2>Successful NFT Minting!</h2>
												<p>Your NFT has been successfully minted.</p>
											</div>
											<div class="pepe">- PEPEMAXI -</div>

										</div>
									</div>

								</div>) : null}

						</div>
						{this.state._ser < 1 ? (<img class="musicOn" src={musicOn} />) : (<img class="musicOn" src={musicOff} />)}

						<img class="pill" onClick={() => window.location.href = 'about'} src={pill} />
						<img class="pill-2" onClick={() => window.location.href = 'bluepill'} src={pill2} />
						<img class="wlCard" onClick={() => window.location.href = 'whitelist'} src={wl} />

						<img class="hackers" src={hacker} />
						<img class="hackersMobile" src={hackerMobile} />
						<div class="audioMain">
							<img class="audioLogo" src={logo} />


							{this.state._ser < 1 ? (

								<div class="audioMain2">

									<div class="music-bar"><span class="bar2 bar-0">0</span><span class="bar2 bar-1">1</span><span class="bar2 bar-2">2</span><span class="bar2 bar-3">3</span><span class="bar2 bar-4">4</span>
									</div>

									<div class="songDetails">
										<div class="songName">{this.state.songNames[currentIndex]}</div>
										<div class="artist">{this.state.artist[currentIndex]}</div>
									</div>

									<img class="playBtn2" onClick={this.playPrevious} src={previous} />
									<img class="playBtn" onClick={this.playAudio} src={playBtn} />
									<img class="playBtn2" onClick={this.playNext} src={next} />
								</div>) :
								(<div class="audioMain2">
									<div class="music-bar"><span class="bar bar-0">0</span><span class="bar bar-1">1</span><span class="bar bar-2">2</span><span class="bar bar-3">3</span><span class="bar bar-4">4</span>
									</div>

									<div class="songDetails">
										<div class="songName">{this.state.songNames[currentIndex]}</div>
										<div class="artist">{this.state.artist[currentIndex]}
										</div>
									</div>

									<img class="playBtn2" onClick={this.playPrevious} src={previous} />
									<img class="playBtn" onClick={this.pauseAudio} src={pauseBtn} />
									<img class="playBtn2" onClick={this.playNext} src={next} />
								</div>)}

						</div>

						<div class="audioMainAb2">
							{this.state._ser < 1 ? (<img class="musicOn2" src={musicOn} />) : (<img class="musicOn2" src={musicOff} />)}

							<div class="audioMainNew">

								<img class="audioLogo2" src={logo} />

								{this.state._ser < 1 ? (

									<div class="audioMain2New">

										<div class="music-bar"><span class="bar2 bar-0">0</span><span class="bar2 bar-1">1</span><span class="bar2 bar-2">2</span><span class="bar2 bar-3">3</span><span class="bar2 bar-4">4</span>
										</div>

										<div class="songDetails">
											<div class="songName">{this.state.songNames[currentIndex]}</div>
											<div class="artist">{this.state.artist[currentIndex]}</div>
										</div>

										<img class="playBtn2" onClick={this.playPrevious} src={previous} />
										<img class="playBtn" onClick={this.playAudio} src={playBtn} />
										<img class="playBtn2" onClick={this.playNext} src={next} />
									</div>) :
									(<div class="audioMain2New">
										<div class="music-bar"><span class="bar bar-0">0</span><span class="bar bar-1">1</span><span class="bar bar-2">2</span><span class="bar bar-3">3</span><span class="bar bar-4">4</span>
										</div>

										<div class="songDetails">
											<div class="songName">{this.state.songNames[currentIndex]}</div>
											<div class="artist">{this.state.artist[currentIndex]}
											</div>
										</div>

										<img class="playBtn2" onClick={this.playPrevious} src={previous} />
										<img class="playBtn" onClick={this.pauseAudio} src={pauseBtn} />
										<img class="playBtn2" onClick={this.playNext} src={next} />
									</div>)}

							</div>
						</div>
					</div>

				</div>

			</div>)
	}
}

export default Home;

import '../App.css';
import React, { Component } from 'react';
import audio2 from '../assets/song2.mp3';
import back from '../assets/back.png';
var $ = require("jquery");

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

let MAX_PER_WALLET;
let public_mint_status = false;
let song;
let song1;

const primaryColor = "#0fce0f";
const source =
  "ﾄﾈｿﾏﾊﾎｩｶﾕｫﾍｪｵﾛｬﾚｸｺﾌｱﾒｰｦｽﾁﾜﾂｹﾗﾓｭﾐﾙﾑﾆｳﾝｯｲﾃﾀﾘｮﾔｾﾋﾅｼｷｴﾖﾉﾇｧｻｨ1234567890";
const getRndInteger = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;
let interval;

const start = () => {
  const canvas = $("#container")[0];
  const rect = canvas.parentElement.getBoundingClientRect();
  canvas.width = rect.width;
  canvas.height = rect.height;
  const ctx = canvas.getContext("2d");
  const size = 14;
  ctx.font = `${size}px sans-serif`;
  const columns = Math.round(canvas.width / size);
  const rows = Math.round(canvas.height / size);
  const columnCells = Array(columns)
    .fill(0)
    .map(() => getRndInteger(0, canvas.height));
  const render = () => {
    ctx.fillStyle = "rgba(0,0,0,.08)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = primaryColor;
    columnCells.forEach((y, index) => {
      const text = source[getRndInteger(0, source.length + 2)] || "";
      const x = index * size;
      ctx.fillText(text, x, y);
      columnCells[index] =
        y > canvas.height + getRndInteger(0, 10000) ? 0 : y + size;
    });
  };
  interval = setInterval(render, 60);
};

class BluePill extends Component {
  state = {
    sound: 0,
    _ser: 0,
    _bluePill: 0,
    _paused: ''
  };

  componentDidMount() {
    try {

      $(function () {
        start();
        $(window).on("resize", () => {
          clearInterval(interval);
          start();
        });
      });
    } catch (err) {
      console.log(err);
    }
  }

  playMusic = () => {
    const song = new Audio(audio2);
    song.play();
    this.setState({ sound: 1 });
  };

  pauseMusic = () => {
    song1.pause();
    this.setState({ _ser: 0 });
  };

  render() {
    return (
      <div className="allWrap">
        <div className="light">
          <div className="page-loaderNew">
            <canvas id="container"></canvas>
            <div className="txt">
              <span className="loadingText">LOADING</span>
            </div>
            <img src={back} onClick={() => window.location.href = '/'} class="back" />
          </div>
        </div>
      </div>
    );
  }
}

export default BluePill;
